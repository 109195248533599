import { UserDto } from '@/api/rest/serviceTransport';
import { IRootState } from '.';

export enum MutationTypes {
  LOGOUT = 'logout',
  LOGIN = 'login',
  SET_CURRENT_USER = 'setCurrentUser',
  SET_TOKEN = 'token',
  SET_COST = 'cost',
  SET_COUNTRY = 'country',
  SET_CURRENCY = 'currency',
}

export type Mutations = {
  [MutationTypes.LOGOUT](state: IRootState): void;
  [MutationTypes.SET_CURRENT_USER](state: IRootState, user: UserDto):any;
  [MutationTypes.SET_TOKEN](state: IRootState, data: { token: string; isSession: false }):any;
  [MutationTypes.SET_COST](state: IRootState, data: { cost: number; isSession: false }):any;
  [MutationTypes.SET_COUNTRY](state: IRootState, data: { country: string; isSession: false }):any;
  [MutationTypes.SET_CURRENCY](state: IRootState, data: { currency: string; isSession: false }):any;
};
