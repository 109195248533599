
import { defineComponent } from 'vue';
import { $t } from '@/utils/i18n';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import { UserService } from '@/api/rest/restClient';
export default defineComponent({
  name: 'AddQuestion',
  components:{
    Button,
    Textarea,
  },
  data() {
    return {
      value : '',
      error : false
    };
  },
  methods:{
    toggleError(err : string){
        this.$toast.add({severity:'error', summary: $t('Error'), detail:err, life: 3000});  
      },
    onSubmit(){
    if (this.value && this.value.length > 0) {
        this.addQuestion()
      }
    else{
      this.error = true  
      }
    },
    resetForm(){
      this.error = false
      this.value = ''
    },
    addQuestion(){
      UserService.askMe({message : this.value})
      .then(() => 
        {
          this.resetForm()
          this.$toast.add({severity:'info', summary: $t('Question.Title'), detail:$t('Question.Sended'), life: 3000})
        })
      .catch((err) => this.toggleError(err?.response?.data))
    }
  }
});
