import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      onClick: _ctx.toggleDropdown,
      label: _ctx.shortCode,
      class: "blue lang"
    }, null, 8, ["onClick", "label"]),
    _createVNode(_component_OverlayPanel, {
      ref: "op",
      appendTo: "body"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_Listbox, {
          modelValue: _ctx.language,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.language) = $event)),
          options: _ctx.languages,
          optionLabel: "name",
          optionValue: "code",
          onChange: _ctx.toggleDropdown
        }, null, 8, ["modelValue", "options", "onChange"]), [
          [_vShow, _ctx.visible]
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}