import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card right"
}
const _hoisted_2 = { class: "flex align-items-center justify-content-end flex-wrap card-container" }
const _hoisted_3 = { class: "flex-grow-1 flex justify-content-start my-2 mr-3" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center ms-2 my-2 ps-5" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!

  return (!_ctx.isAuth)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Login.Title')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              class: "green middle",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('register'))),
              label: _ctx.$t('Register.Title')
            }, null, 8, ["label"])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Account.Title')), 1)
      ]))
}