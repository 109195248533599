
import { defineComponent } from 'vue';
import { version } from '@/constants/appVersion';
import { apiUrlPath } from '@/constants/apiUrl';
export default defineComponent({
  name: 'Footer',
  data() {
    return {
      version: version,
      url: apiUrlPath.url!.replace('https://', ''),
    };
  },
});
