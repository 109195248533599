
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import User from '@/components/User/User.vue';
import { BillingService, UserService } from '@/api/rest/restClient';
import { UserDto } from '@/api/rest/serviceTransport';
import store from '@/store';
import { MutationTypes } from '@/store/mutations';
import Header from '@/components/Header/Header.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    Button,
    Header,
    User
  },
  data(){
    return{}
    },
  created(){
     UserService.getCurrentUser()
      .then((result) => {
          store.commit(MutationTypes.SET_CURRENT_USER, result as UserDto);
      })
      .catch((err) => {
          console.error(err?.response?.data as string);
      });
    BillingService.getCostPerMinute()
    .then((result) => {
        let session = store.getters.isSession
        store.commit(MutationTypes.SET_COST, { cost: result as number, isSession: session });
      })
      .catch((err) => {
          console.error(err?.response?.data as string);
      });  
  },
  methods:{
    logout(){
        store.commit(MutationTypes.LOGOUT);
    }
  },
  computed:{
    isAuth : bool => store.getters.isAuthenticated
  }  
});
