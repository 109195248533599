export enum Languages {
  English = 'English',
  Russain = 'Русский',
}

export enum LanguageCode {
  en_GB = 'en-GB',
  ru_RU = 'ru-RU',
}

export enum ShortCode {
  en_GB = 'En',
  ru_RU = 'Ru',
}

export namespace LanguageCode {
  export function toString(dir: LanguageCode): string {
      return LanguageCode[dir];
  }
  export function fromString(dir: string): LanguageCode {
      return (LanguageCode as any)[dir];
  }
}