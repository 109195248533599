
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageForm',
  props: {
      isProgress: Boolean
  },
  watch:{
    isProgress: {
        immediate: true,
        handler(val, oldVal) {
          this.visible = val
        },
    }
  }
});
