import { LanguageCode, Languages, ShortCode } from '@/enums/localization/localization.enum';

export const LanguagesArray: ILanguage[] = [
  { name: Languages.Russain, code: LanguageCode.ru_RU, shortCode : ShortCode.ru_RU },
  { name: Languages.English, code: LanguageCode.en_GB, shortCode : ShortCode.en_GB },
];

export const defaultLanguage = LanguageCode.ru_RU;

export interface ILanguage {
  name: Languages;
  code: LanguageCode;
  shortCode : ShortCode;
}
