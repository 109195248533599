<template>
    <ImageForm 
        :isProgress="showProgress">
        <template v-slot:title>
            {{isError ? $t('Login.Error') : $t('Login.Title')}}
        </template>
        <template v-slot:context>
            <div v-if="!showMessage">
                <form @submit.prevent="handleSubmit(!v$.invalid)" class="p-fluid">
                    <div class="mt-5 mb-3">
                        <div class="p-float-label p-input-icon-right">
                            <i class="pi pi-envelope" />
                            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                            <label for="email" :class="{'p-error':v$.email.$invalid  && submitted}">Email*</label>
                        </div>
                        <span v-if="v$.email.$error  && submitted">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small v-else-if="(v$.email.$invalid  && submitted) || v$.email.$pending" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                    </div>
                    <div class="mt-5 mb-3">
                        <div class="p-float-label">
                            <Password id="password" v-model="v$.password.$model" 
                                :class="{'p-invalid':v$.password.$invalid  && submitted}" aria-describedby="password-error" 
                                :feedback="false" toggleMask />
                            <label for="password" :class="{'p-error':v$.password.$invalid  && submitted}">{{$t('Password')}}*</label>
                        </div>
                        <span v-if="v$.password.$error  && submitted">
                            <span id="password-error" v-for="(error, index) of v$.password.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small v-else-if="(v$.password.$invalid  && submitted) || v$.password.$pending" class="p-error">{{v$.password.required.$message.replace('Value', 'Email')}}</small>
                    </div>
                    <div class="field-checkbox">
                        <Checkbox id="rememberme" name="rememberme" :binary="true" v-model="isRememberMe" />
                        <label for="rememberme" >{{$t('RememberMe')}}</label>
                    </div>
                    <div class="field">
                        <router-link to="/resetpassword">{{$t('FrogotPassword')}}</router-link>    
                    </div>
                    <Button type="submit" class="form blue" :label="$t('Login.Title')"/>
                </form>
            </div>
            <div v-else class="w-full justify-content-center align-items-stretch">
                <h2 :class="{error : isError}">{{message}}</h2>
                <Button @click="resetForm()" :label="buttonTitle" class="red form" />
            </div>
        </template>
    </ImageForm>
</template>
<script>
    import login from './login';
    export default login;
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/styles.scss";
    @import "@/assets/styles/login.scss";
</style>