
import { defineComponent } from 'vue'
import Dropdown from 'primevue/dropdown'
import { AuthenticationService } from '@/api/rest/restClient';

export default defineComponent({
  name: 'CountrySelector',
  props:['modelValue','country'],
  emits: ['update:modelValue'],
  components:{
    Dropdown,
  },
  data() {
    return {
        countries : [],
       // selectedCountry : null,
        code : ''
    }
  },
  methods:{
    getCountries(){
        AuthenticationService.getCountries()
        .then((result) => {
                this.countries = result
                this.findCountry()
            })
            .catch((err) => {
                console.error(err?.response?.data as string);
            });
    },
    setValue(){
      this.$emit('input:modelValue', this.code)
      console.log('emit ' + this.modelValue)
    },
    getFlag(code){
      if(!code) return  
      return 'fi-' +  code.toLowerCase()
    },
    findCountry(){
        this.selectedCountry = this.countries.find((c) => c.code === this.code)
    }
  },
  created(){
     this.getCountries()
  },
  mounted() { 
    this.emitter.on("set-language", () => {
      this.getCountries()
    })
  },
  computed: {
    selectedCountry: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch:{
    country: {
        immediate: true,
        handler(val, oldVal) {
          this.code = val
          this.findCountry()
        },   
    }
  }
});
