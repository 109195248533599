
import { defineComponent } from 'vue'
import Button from 'primevue/button'
import store from '@/store/'
export default defineComponent({
    name:'HeaderNavigator',
    data(){
        return{
        }
    },
    components: {
        Button,
        },
    computed:{
        isAuth : bool => store.getters.isAuthenticated
  }})
