import { $t } from "@/utils/i18n"

export default class Formatter{
static formatAmountMinutes(minutes) {
    var amount = parseFloat(minutes)
    if (isNaN(amount)) { return 'n/a' }
    var hour = Math.floor(amount / 60)
    var min = amount % 60
    return (hour > 0 ? hour.toString() + '\u00A0'+ $t('Account.Hours') +  '\u00A0' : '') + (min > 0 ? min.toFixed().toString() : '00') + '\u00A0' + $t('Account.Minutes') 
}
static formatAmountSeconds(seconds) {
    var amount = parseFloat(seconds)
    if (isNaN(amount)) { return 'n/a' }
    var hour = Math.floor(amount / 3600)
    var min = Math.floor((amount - hour * 3600.0) / 60)
    return (hour > 0 ? hour.toString() + '\u00A0'+ $t('Account.Hours') +  '\u00A0' : '') + (min > 0 ? min.toFixed().toString() : '00') + '\u00A0' + $t('Account.Minutes') 
}

static formatSeconds(seconds : number) {
    var str = new Date(seconds * 1000).toISOString()
    //let result = str.match(/([1-9]{1,2}):(\d{2}):(\d{2}):(\d{2})|([1-9]{1,2}):(\d{2}):(\d{2})|([0]{2}):([1-9]{1})([0]{1}):(\d{2})|([0]{2}):([0]{1})([1-9]{1}):(\d{2}):(\d{2})|([0]{2}):([0]{1})([0]{1}):(\d{2})/g);
    let result = str.match(/([0-9]{1,2}):(\d{2}):(\d{2})/g);
    return  result ? result[0] : 'n/a'
  }
}