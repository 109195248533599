import { defineComponent, } from 'vue';
import { AuthenticationService } from '@/api/rest/restClient';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import ImageForm from '@/components/ImageForm/ImageForm.vue'
import {$t, i18n} from '@/utils/i18n'
import { helpers } from '@vuelidate/validators';

export default defineComponent({
    components:{
        Password,
        Button,
        InputText,
        Checkbox,
        ImageForm
    },
    
    name: 'ResetPassword',

    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { 
              required :helpers.withMessage(`Поле 'email' не может быть пустым`, required),
              email: helpers.withMessage(`Ошибка ввода поля 'email'`, email), 
            }
        }
    },
    data(){
      return {
        email: '',
        message:'',
        success: false,
        isError : false,
        submitted:false,
        showMessage: false,
        showProgress: false,
      }
    },
    created() {
      
    },
    mounted() {
    },
    computed:{
      tryitagain :() => $t('TryItNow'),
    },
    methods: {
      resetPassword: function () {
        let email = this.email;
        this.showProgress = true;
        AuthenticationService.recoveryPassword({email})
            .then(data => this.processResetPasswordResult())
            .catch(err => this.processResetPasswordError(err?.response?.data))
      },
      processResetPasswordResult(): void {
        this.showProgress = false
        this.message = $t('ResetPassword.Success')
        this.showMessage = true
      },
      processResetPasswordError(err: String): void {
        this.showProgress = false
        this.isError = true
        this.message = err
        this.showMessage = true
    },
    resetForm() {
        this.email = '';
        this.submitted = false;
        this.showProgress = false
        this.isError = false
        this.showMessage = false
    },
    handleSubmit(isFormValid) {
      this.submitted = true;  
      if (!isFormValid) {
                return
            }
        this.resetPassword();
      },
    }
  })
