import { defineComponent, } from 'vue';
import { UserDto } from '@/api/rest/serviceTransport';
import { AuthenticationService, UserService } from '@/api/rest/restClient';
import store from '@/store';
import { ICurrentUser } from '@/interfaces/local-interfaces/authentication/user-dto';
import { MutationTypes } from '@/store/mutations';
import router from '@/router/'
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Password from "primevue/password";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import ImageForm from '@/components/ImageForm/ImageForm.vue'
import { $t } from '@/utils/i18n';
import Api from '@/api/api';

export default defineComponent({
    components:{
        Dialog,
        Password,
        Button,
        InputText,
        Checkbox,
        ImageForm,
    },
    name: 'Login',

    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { required, email },
            password: { required }
        }
    },
    data(){
      return {
        email: '',
        password: '',
        message:'',
        success: false,
        submitted : false,
        isRememberMe : false,
        isError : false,
        date: null,
        accept: null,
        showMessage: false,
        showProgress: false,
        seconds : 30 as number,
        timer : null
      }
    },
    created() {
    },
    mounted() {
      this.progress = false
        
    },
    computed:{
      buttonTitle(){ 
        return $t('Button.TryItNow') + '(' + this.seconds +')'
      }
    },
    methods: {
      login: function () {
        this.showProgress = true
        this.isError = false
        let loginDto = {
            email: this.email,
            password: this.password,
            deviceType: 0,
            };
        AuthenticationService.login({ body: loginDto })
            .then(data => this.processLoginResult(data as UserDto))
            .catch(err => this.processLoginError(err?.response?.data))
      },
      processLoginResult(data: UserDto): void {
        const user = data as ICurrentUser;
        this.showProgress = false
        if (!user.token) return;
        this.success = true;
        Api.instance.LoginUser(user)
        router.push('/')
        this.severity = 'success'
      },
      processLoginError(err: string): void {
        this.timer = setInterval(this.updateTime, 1000);
        this.showProgress = false
        this.message = err
        this.showMessage = true
        this.isError = true
      },
      handleSubmit(isFormValid) {
            this.submitted = true
            if (!isFormValid) {
                return;
            }
            this.login();
        },
      toggleDialog(err : string) {
            this.error = err
            this.showProgress = false
            this.$toast.add({severity: 'error', summary: $t('Error'), detail: err});
        },
      resetForm() {
        this.email = ''
        this.password = ''
        this.showProgress = false
        this.message = ''
        this.showMessage = false
        this.isError = false
        this.submitted = false
        if(this.timer){
          clearTimeout(this.timer)
          this.seconds = 30
        }
      },
      updateTime(){
        if(this.seconds < 2){
          this.resetForm()
        }
        this.seconds -= 1  
      },
    }
  })

