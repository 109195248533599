import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa154db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "billing-wrapper result" }
const _hoisted_2 = { class: "header flex success justify-content-center align-items-center" }
const _hoisted_3 = { class: "flex align-items-center justify-content-center my-8" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex align-items-center justify-content-center mt-5" }
const _hoisted_6 = { class: "flex align-items-center justify-content-center mb-5" }
const _hoisted_7 = { class: "flex flex-wrap align-items-end justify-content-center my-8" }
const _hoisted_8 = { class: "ammount-title flex flex-wrap align-items-end" }
const _hoisted_9 = { class: "ammount-title flex flex-wrap align-items-end" }
const _hoisted_10 = { class: "flex justify-content-center align-content-center pb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('Billing.PaymentCompletion')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: require('@/assets/images/ok.png')
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Billing.PaymentSuccess')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('Billing.YourPay')), 1),
      _createElementVNode("span", null, " " + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.amount), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Billing.YourAmmount')) + " ", 1),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.balans), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_Button, {
        type: "button",
        class: "blue big",
        label: _ctx.buttonTitle,
        onClick: _ctx.ok
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}