import { UserDto } from '@/api/rest/serviceTransport';
import { IRootState } from '.';
import { IStateLocalizationTypes } from './localization/localization';

export enum Getters {
  currentUser = 'currentUser',
  isAuthenticated = 'isAuthenticated',
  token = 'token',
  localization = 'localization',
  isSession = 'isSession',
  costPerMinute = 'costPerMinute',
  country = 'country',
  currency = 'currency'
}

export type GettersTypes = {
  isAuthenticated: (state: IRootState) => boolean;
  currentUser: (state: IRootState) => UserDto | null;
  token: (state: IRootState) => string | null;
  localization: (state: IRootState) => IStateLocalizationTypes | null;
  isSession: (state: IRootState) => boolean;
  costPerMinute : (state: IRootState) => number | null;
  country : (state: IRootState) => string | null;
  currency :(state: IRootState) => string | null;
};
