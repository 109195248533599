import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "country-item country-value"
}
const _hoisted_2 = { class: "country-name" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "country-item" }
const _hoisted_5 = { class: "country-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.selectedCountry,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCountry) = $event)),
    options: _ctx.countries,
    optionLabel: "name",
    filter: true,
    placeholder: _ctx.$t('SelectCountry'),
    showClear: true
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(["fi mr-1", _ctx.getFlag(slotProps.value.code2)])
            }, null, 2),
            _createElementVNode("span", _hoisted_2, " " + _toDisplayString(slotProps.value.name), 1)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.placeholder), 1))
    ]),
    option: _withCtx((slotProps) => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass(["fi mr-1", _ctx.getFlag(slotProps.option.code2)])
        }, null, 2),
        _createElementVNode("span", _hoisted_5, " " + _toDisplayString(slotProps.option.name), 1)
      ])), [
        [
          _directive_tooltip,
          slotProps.option.name,
          void 0,
          { top: true }
        ]
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options", "placeholder"]))
}