<template>
    <ImageForm
        :isProgress="showProgress">
        <template v-slot:title>
            {{isError ? $t('ResetPassword.Error') : $t('ResetPassword.Title')}}
        </template>
        <template v-slot:context>
            <div v-if="!showMessage" class="justify-content-center">
                <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                    <div class="field mb-4">
                        {{$t('ResetPassword.Message')}}
                    </div>
                    <div class="field">
                        <div class="p-float-label p-input-icon-right">
                            <i class="pi pi-envelope" />
                            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                            <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
                        </div>
                        <span v-if="v$.email.$error && submitted">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                    </div>
                    <Button type="submit" class="form blue" :label="$t('Submit')"/>
                </form>
            </div>
            <div v-else class="justify-content-center">
                <h2 v-if="!showProgress" :class="{error : isError}">{{message}}</h2>
                <div v-if="isError" class= "flex w-full justify-content-center">
                    <Button @click="resetForm()" :label="tryitagain" class="red form" />
                </div>
            </div>
        </template>
    </ImageForm>
</template>
<script>
    import login from './resetpassword';
    export default login;
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/styles.scss";
    .warn{
        color: $gray;
    }
    .error{
        color:$red;
    }
</style>