import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "questions p-5" }
const _hoisted_2 = { class: "flex align-items-center justify-content-center mb-5" }
const _hoisted_3 = { class: "flex flex-wrap justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Textarea, {
        id: "value",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        class: _normalizeClass(["flex", { 'p-invalid': _ctx.error }]),
        rows: "5",
        cols: "40",
        placeholder: _ctx.$t('Question.Question'),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (this.error = false)),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (this.error = false))
      }, null, 8, ["modelValue", "class", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('Button.Submit'),
        class: "flex big red",
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}