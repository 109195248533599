import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/logout.png'


const _withScopeId = n => (_pushScopeId("data-v-d2ca10fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex mb-5" }
const _hoisted_2 = { class: "flex-none flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "flex-grow-1 flex align-items-center justify-content-end" }
const _hoisted_4 = { class: "flex-none flex align-items-center justify-content-center p-2 mr-6" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "none",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
      }, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('Account.Title')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          href: _ctx.$t('Account.Manual'),
          target: "blank"
        }, _toDisplayString(_ctx.$t('Account.Instruction')), 9, _hoisted_5)
      ]),
      _createVNode(_component_Button, {
        type: "button",
        class: "justify-content-end logout"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            alt: "$t('Account.Logout')",
            src: _imports_0,
            style: {"width":"1.5rem"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
          })
        ]),
        _: 1
      })
    ])
  ]))
}