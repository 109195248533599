import { createRouter, createWebHistory, useRoute } from 'vue-router'
import store from '@/store/'
import HomeView from '../views/HomeView.vue'
import User from '../components/User/User.vue'
import RegisterConfirm from '../components/RegisterConfirm/RegisterConfirm.vue'
import ResetPassword  from '../components/ResetPassword/ResetPassword.vue'
import Api from '@/api/api'
import { AuthenticationService, UserService } from '@/api/rest/restClient'
import { UserDto } from '@/api/rest/serviceTransport'
import { ICurrentUser } from '@/interfaces/local-interfaces/authentication/user-dto'
import LoginView from '@/views/LoginView.vue'
import Login from '@/components/Login/Login.vue'
import Register from '@/components/Register/Register.vue'
import Billing from '@/components/Billing/Billing.vue'
import Account from '@/components/Account/Account.vue'
import PaySuccess from '@/components/PaySuccess/PaySuccess.vue'
import PayFail from '@/components/PayFail/PayFail.vue'

const checkToken = async (token : string) => {
  try{
    const data = await AuthenticationService.loginByToken({token})
    const user = data as ICurrentUser
    if (user.token) {
      Api.instance.LoginUser(user)
      return user
    }
  }catch(err){
      console.error(err)
  }
}
const ifAuthenticated = async (to, from, next)=>{
  if(store.getters.isAuthenticated){
    next()    
  }
  next({name:'login'})
}

const ifNotAuthenticated = (to, from, next)=>{
  if(!store.getters.isAuthenticated){
    next({name:'login'})    
  }
  next()
}


const routes  = [
  {
    //beforeEnter : ifAuthenticated,
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name : 'user',
        path: '',
        component: User,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            name : 'account',
            path: '',
            component: Account,
            meta: {
              requiresAuth: true,
            },
          },
          {
            name : 'billing',
            path : 'billing',
            props : route => ({ minutes: route.query.minutes }),
            component: Billing,
            meta: {
              requiresAuth: true,
            }
          },
          {
            name : 'paysuccess',
            path: 'billing/success',
            component: PaySuccess,
            meta: {
              requiresAuth: true,
            },
          },
          {
            name : 'payfail',
            path: 'billing/fail',
            component: PayFail,
            meta: {
              requiresAuth: true,
            },
          },
        ]
      }
    ]
  },
  {
    path:'/login',
    name: 'loginview',
    component: LoginView,
    meta: { 
      guest: true
    },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: { 
          guest: true
        }
      },
      {
        path: '/resetpassword',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { 
          guest: true
        }
      },
      {
        path: '/register',
        name: 'register',
        component: Register,
        meta: { 
          guest: true
        }
      },
      {
        name: 'registerconfirm',
        path: '/registerconfirm',
        props: route => ({ token: route.query.token }),
        meta: { 
          guest: true
        },
        component: RegisterConfirm
      },
    ]
  },
]

const router = createRouter({
  history :createWebHistory(),
  routes
})




router.beforeEach(async (to, from, next) => {
  const lang = (to.query['lang']??store.state.localization.language) as string
  const locale = lang.toLowerCase().includes('ru') ? 'ru-RU' : 'en-GB'
  store.dispatch('SET_LANGUAGE', locale)
  //проверка маршрута регистрации
  if(to.name == 'registerconfirm') {
    next()
    return
  }
  // проверка токена, сделаем переход с токена на любую страницу
  const tkn = to.query['token'] as string
  if(tkn) {
    await checkToken(tkn)
    //пробуем вырезать часть строки с токеном авторизации
    const query = to.query
    delete query['token']
    to.query = query
    router.replace(to)
  }
  if(to.name != 'login' && !store.getters.isAuthenticated){
      next({name:'login'})
      return
    }
  else {
    next()
    return
  }
})
export default router
