import { LanguageCode } from '@/enums/localization/localization.enum';
import { IGlobalLocalLocalization } from "./localization.global";

export const russainLocalization: IGlobalLocalLocalization = {
  messages: {
    LocaleString : 'ru-RU',
    
    Test : "Тестовое сообщение",
    HelloWorld : "Привет Vue!",
    Login : {
      Title : "Войти",
      Error : "Ошибка входа"
    },
    Home : "Главная",
    Language: "Язык",
    About : 'О компании',
    SelctLanguage: "Выберите язык",
    Country: "Страна",
    SelectCountry: "Выберите страну",
    ChangeCountry: "Изменить страну",
    Email: "Email",
    Error:'Ошибка',
    Name: 'Имя',
    Password: "Пароль",
    ConfirmPassword: "Подтверждение пароля",
    Phone:'Телефон',
    EnterEmail: "Введите email",
    EnterPassword: "Введите пароль",
    EnterPhoneNumber: "Введите номер телефона",
    SelectLanguage: 'Выберите язык',
    RememberMe: 'Запомнить меня на этом компьютере',
    Welcome: 'Добро пожаловать!',
    FrogotPassword:'Забыли пароль?',
    PickAPassword:'Введите пароль',
    Suggestions:'Правила для пароля',
    Button:{
      Ok : 'OK',
      Cancel : 'Отмена',
      TryItNow : 'Еще раз',
      Submit: 'Отправить'
    },
    Billing:{
      Header : 'Пополнить баланс',
      Title : 'Выбирайте подходящий пакет со скидкой',
      Title2: 'Или вводите точное количество минут',
      ToPay : 'К оплате',
      CostPerMinuteRub : '₽/мин.',
      CostPerMinuteUsd : '$/мин.',
      Pay: 'Оплатить',
      PaymentCompletion : 'Завершение оплаты',
      PaymentSuccess : 'Оплата успешно завершена',
      PaymentFail : 'Оплата завершена с ошибкой',
      ErrorCode : 'Код ошибки',
      YourAmmount : 'Ваш баланс',
      YourPay : 'Вы совершили оплату на'
    },
    Register: {
      Title: 'Регистрация',
      Error: 'Ошибка регистрации',
      Success :'Пожалуйста, проверьте Вашу почту и подтвердите регистрацию.',
      ConfirmSuccess :"Ваша учетная запись успешна подтверждена. Вы можете начать работу с приложением.",
      Confirm :'Подтверждение регистрации',
      ConfirmError :'Ошибка подтверждения регистрации',
      Agreement: 'Я согласен с условиями регистрации',
      AgreementLink: 'http://stenogram.pro/wp-content/uploads/2022/06/polzovatelskoe_soglashenieN.pdf'
    },
    Time:{
      minutes : 'минут',
      minutes2 : 'минуты',
      minute : 'минута',
      h1 : 'час',
      h24 : 'часа',
      h5 : 'часов',
      min : 'мин'
    },
    ResetPassword:{
      Title : 'Восстановление пароля',
      Email : 'Введите адрес электронной почты',
      Error : 'Ошибка восстановления пароля',
      Message : 'Введите ваш адрес электронной почты. Мы отправим на него новый пароль.',
      Success : 'Новый пароль выслан на Вашу почту.'
    },
    Submit : 'Отправить',
    "SuggestionsList": {
        '0' : 'Не менее одного символа в нижнем регистре', 
        '2' : 'Не менее одного символа в врехнем регистра', 
        '3' : 'Не менее одного числа', 
        '4' : 'Минимальная длина 8 символов'
    },
    PasswordLabels: {
      weakLabel	: 'Слабый',
      mediumLabel :	'Средний',
      strongLabel :	'Отличный!'
    },
    validations: {
      required: 'Поле Value не может быть пустым.',
      email: 'Неверное значение электронной почты',
      minLength : 'Длина поля Value не может быть меньше {min} символов.',
      sameAs : 'Значения в полях Пароль и Подтверждение пароля не совпадают.'
    },
    Account : {
      Title : "Личный кабинет",
      Support : "Написать в техподдержку",
      Instruction :  "Пошаговая инструкция",
      Logout : "Выход",
      UserCredential : "Персональные данные",
      Download : "Скачать Stenogram для PC",
      Name:"Имя",
      Email:"Email",
      Amount:"Баланс",
      Refill:"Пополнить",
      Hours:'час.',
      Minutes:'мин.',
      Manual:'https://stenogram.pro/poshagovaya-instrukciya/'
    },
    Question:{
      Question : 'Введите Ваш вопрос',
      Title : 'Вопрос',
      Sended : 'Ваш вопрос был отправлен'
    },
    Project:{
      Header : 'Проекты',
      Title : 'Проект',
      File : 'Файл',
      Date : 'Дата',
      Minute : 'Минуты',
      Size : 'Размер',
      Time: 'Длительность'
    },
    Size:{
      byte :'Б', 
      kilobyte : 'кБ', 
      megabyte: 'мб', 
      gigabyte: 'ГБ', 
      terabyte: 'ТБ'
    },
    Currency:{
      rub : 'руб',
      usd : 'usd',
      rubS : '₽',
      usdS : '$',
    }
  },
  languageCode: LanguageCode.ru_RU,
};
