import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-074314e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AddQuestion = _resolveComponent("AddQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserMenu, {
      onAccount: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isProject = true))
    }),
    _createVNode(_component_router_view),
    _createVNode(_component_AddQuestion)
  ]))
}