import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d66fe67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account mb-5" }
const _hoisted_2 = { class: "grid w-full account-props" }
const _hoisted_3 = { class: "col-2" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "col-2" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "col-3 btn flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Projects = _resolveComponent("Projects")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Account.UserCredential')), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CountryFlag, {
            modelValue: _ctx.country,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.country) = $event)),
            country: _ctx.currentUser.country,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCountry()))
          }, null, 8, ["modelValue", "country"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('Account.Name')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.currentUser.fullName), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('Account.Email')), 1),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.currentUser.email), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('Account.Amount')), 1),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.formatAmount(_ctx.currentUser.amount)), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_Button, {
            type: "button",
            class: "red refill",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('billing'))),
            label: _ctx.$t('Account.Refill')
          }, null, 8, ["label"])
        ])
      ])
    ]),
    _createVNode(_component_Projects)
  ], 64))
}