import { LanguageCode } from '@/enums/localization/localization.enum';
import { IGlobalLocalLocalization } from './localization.global';

export const englishLocalization: IGlobalLocalLocalization = {
  messages: {
    LocaleString : 'en-GB',
    Test : "Test",
    HelloWorld : "Hello Vue!",
    Login : {
      Title : "Login",
      Error : "Login error"
    },
    Home : "Home",
    Language: "Language",
    About : 'About us',
    SelctLanguage: "Select language",
    Email: "Email",
    Error:'Error',
    Name: 'Name',
    Password: "Password",
    ConfirmPassword: "Confirm password",
    Phone:'Phone',
    EnterEmail: "Enter email",
    EnterPassword: "Enter password",
    EnterPhoneNumber: "Enter a phone number",
    RememberMe : 'Remember me on this computer',
    Country: "Country",
    SelectCountry: "Select country",
    ChangeCountry: "Change country",
    Welcome: 'Welcome!',
    FrogotPassword:'Forgot password?',
    PickAPassword:'Pick a password',
    Suggestions:'Suggestions',
    Submit : 'Submit',
    Button:{
      Ok : 'OK',
      Cancel : 'Cancel',
      TryItNow : 'Try it now',
      Submit: 'Submit'
    },
    Billing:{
      Header : 'Top up balance',
      Title : 'Select your tariff',
      Title2: 'Or enter the exact number of minutes',
      ToPay : 'To pay',
      CostPerMinuteRub : '₽/min.',
      CostPerMinuteUsd : '$/min.',
      Pay: 'Pay',
      PaymentCompletion : 'Payment completion',
      PaymentSuccess : 'Payment successfull completed',
      PaymentFail : 'Payment error',
      ErrorCode : 'Error code',
      YourAmmount : 'Your ammount',
      YourPay : 'You payed'
    },
    Register: {
      Title: 'Register',
      Error: 'Register error',
      Success :'Please check your Email to confirm registration.',
      ConfirmSuccess :"Yor registration confirmed successfull!",
      Confirm :'Register confirm',
      ConfirmError :'Confirm error',
      Agreement: 'I agree to the terms and conditions',
      AgreementLink: 'http://stenogram.pro/wp-content/uploads/2023/03/polzovatelskoe_soglashenieRK.pdf'
    },
    ResetPassword:{
      Title : 'Reset password',
      Email : 'Enter e-mail',
      Error : 'Error password reset',
      Message : 'Enter you Email. We will send a new password to your Email.',
      Success : 'New password has been sent to your Email.'
    },
    SuggestionsList: {
      '0' : 'At least one lowercase', 
      '2' : 'At least one uppercase', 
      '3' : 'At least one numeric', 
      '4' : 'Minimum 8 characters'
    },
    PasswordLabels:{
      weakLabel	: 'Weak',
      mediumLabel :	'Medium',
      strongLabel :	'Strong'
    },
    validations: {
      required: 'The field Value is required.',
      email: 'Invalid email',
      minLength : 'The Value field has a value of but it must have a min length of {min}.',
      sameAs : 'The @:Password and @:ConfirmPassword password fields do not match' 
    },
    Account : {
      Title : "My account",
      Support : "Support",
      Instruction :  "Instruction",
      Logout : "Logout",
      UserCredential : "User account",
      Download : "Download Stenogram for PC",
      Name:"Name",
      Email:"Email",
      Amount:"Amount",
      Refill:"Refill",
      Hours:'h.',
      Minutes:'min.',
      Manual:'https://stenogram.pro/en/poshagovaya-instrukciya-english/',
    },
    Question:{
      Question : 'Enter your question',
      Title : 'Question',
      Sended : 'Your question has been send'
    },
    Project:{
      Header : 'Projects',
      Title : 'Project',
      File : 'File',
      Date : 'Date',
      Minute : 'Minute',
      Size : 'Size',
      Time: 'Duration'
    },
    Size:{
      byte :'B', 
      kilobyte : 'kB', 
      megabyte: 'MB', 
      gigabyte: 'GB', 
      terabyte: 'TB'
    },
    Time:{
      minutes : 'minutes',
      minutes2 : 'minutes',
      minute : 'minute',
      h1 : 'hours',
      h24 : 'hours',
      h5 : 'hours',
      min : 'min'
    },
    Currency:{
      rub : 'rub',
      usd : 'usd',
      rubS : '₽',
      usdS : '$',
    }
  },
  languageCode: LanguageCode.en_GB,
};
