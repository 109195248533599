import { ActionTree, createStore, GetterTree, MutationTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { Getters, GettersTypes } from './getters';
import { AcionsTypes } from './actions';
import { VueCookieNext } from 'vue-cookie-next';
import { storeModules } from './store-manager/store.modules';
import Api from '@/api/api';
import {  UserDto } from '@/api/rest/serviceTransport';
import { LanguageCode } from '@/enums/localization/localization.enum';
import { IStateLocalizationTypes } from './localization/localization';

const tokenKey = 'token';
const currentUser = 'currentUser';
const isSessionKey = 'isSession';
const costPerMinute = 'costPerMinute';
const country = 'country';
const currency = 'currency';

export interface IRootState {
  currentUser: UserDto | null;
  token: string | null;
  localization: IStateLocalizationTypes;
  isSession: boolean;
  costPerMinute: number;
  country: string | null;
  currency: string | null;
}

const state: IRootState = {
  token: localStorage.getItem(tokenKey) ?? sessionStorage.getItem(tokenKey) ?? null,
  localization: {language : localStorage.getItem('language') ?? LanguageCode.en_GB},
  currentUser: (JSON.parse(localStorage.getItem(currentUser)!) as UserDto) || (JSON.parse(sessionStorage.getItem(currentUser)!) as UserDto) || null,
  isSession: (JSON.parse(localStorage.getItem(isSessionKey)!) as boolean) || false,
  costPerMinute: (JSON.parse(localStorage.getItem(costPerMinute)!) as number) || (JSON.parse(sessionStorage.getItem(costPerMinute)!) as number) || 0.15,
  country: (JSON.parse(localStorage.getItem(country)!) as string) || (JSON.parse(sessionStorage.getItem(country)!) as string) || 'RUS',
  currency: (JSON.parse(localStorage.getItem(currency)!) as string) || (JSON.parse(sessionStorage.getItem(currency)!) as string) || 'RUB',
};

const getters: GetterTree<IRootState, IRootState> & GettersTypes = {
  [Getters.isAuthenticated]: (state: IRootState) => !!state.token,
  [Getters.currentUser]: (state: IRootState) => state.currentUser,
  [Getters.token]: (state: IRootState) => state.token,
  [Getters.localization]: (state: IRootState) => state.localization,
  [Getters.isSession]: (state: IRootState) => state.isSession,
  [Getters.costPerMinute]: (state: IRootState) => state.costPerMinute,
  [Getters.country]: (state: IRootState) => state.country,
  [Getters.currency]: (state: IRootState) => state.currency,
};

const actions: ActionTree<IRootState, IRootState> & AcionsTypes = {};

const mutations: MutationTree<IRootState> & Mutations = {
  [MutationTypes.LOGOUT](state: IRootState) {
    state.token = null;
    state.currentUser = null;
    state.country = null;
    state.currency = null;
    state.costPerMinute = 0;
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(currentUser);
    localStorage.removeItem(currency);
    localStorage.removeItem(country);
    localStorage.removeItem(costPerMinute);
    
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(currentUser);
    sessionStorage.removeItem(currency);
    sessionStorage.removeItem(country);
    sessionStorage.removeItem(costPerMinute);
    VueCookieNext.keys().forEach((cookie:any) => {
      return VueCookieNext.removeCookie(cookie);
    });
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  },
  [MutationTypes.SET_CURRENT_USER](state: IRootState, user: UserDto): void {
    state.currentUser = user;
    const setUser = JSON.stringify(user);
    if (!state.isSession) {
      localStorage.setItem(currentUser, setUser);
    } 
    else {
      sessionStorage.setItem(currentUser, setUser);
    }
  },
  [MutationTypes.SET_TOKEN](state: IRootState, data: { token: string; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
      localStorage.setItem(isSessionKey, JSON.stringify(data.isSession as boolean));
      state.isSession = data.isSession;
      state.token = data.token;
      Api.instance.authorize(data.token);
      if (!data.isSession) {
        localStorage.setItem(tokenKey, data.token);
      } 
      else {
        sessionStorage.setItem(tokenKey, data.token);
      }
  },
  [MutationTypes.SET_COST](state: IRootState, data: { cost: number; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
    state.costPerMinute = data.cost;
    if (!data.isSession) {
      localStorage.setItem(costPerMinute, JSON.stringify(data.cost as number));
    } 
    else {
      sessionStorage.setItem(costPerMinute, JSON.stringify(data.cost as number));
    }
  },
  [MutationTypes.SET_COUNTRY](state: IRootState, data: { country: string; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
    state.country = data.country;
    if (!data.isSession) {
      localStorage.setItem(country, JSON.stringify(data.country as string));
    } 
    else {
      sessionStorage.setItem(country, JSON.stringify(data.country as string));
    }
  },
  [MutationTypes.SET_CURRENCY](state: IRootState, data: { currency: string; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
    state.country = data.currency;
    if (!data.isSession) {
      localStorage.setItem(currency, JSON.stringify(data.currency as string));
    } 
    else {
      sessionStorage.setItem(currency, JSON.stringify(data.currency as string));
    }
  }
};

const store = createStore({
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: storeModules,
});

export default store;
