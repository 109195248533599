
import { defineComponent } from 'vue'
import Button from 'primevue/button'
import store from '@/store/'
import { MutationTypes } from '@/store/mutations'
export default defineComponent({
    name:'UserMenu',
    data(){
        return{
        }
    },
    components: {
        Button,
        },
    computed:{
        isAuth : bool => store.getters.isAuthenticated
    },
    methods:{
        logout(){
            store.commit(MutationTypes.LOGOUT);
        }
    }
})
