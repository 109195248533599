import { defineComponent, ref } from 'vue';
import { LanguagesArray } from '@/constants/localization/localization.constans' 
import { LanguageCode, ShortCode } from '@/enums/localization/localization.enum'
import Button from 'primevue/button';
import Listbox from 'primevue/listbox';
import OverlayPanel from 'primevue/overlaypanel';
import store from '@/store'
export default defineComponent({
  name: 'LangSelector',
  components:{
    Listbox,
    OverlayPanel,
    Button
  },
  data(){
    return {
      language : LanguageCode.en_GB,
      languages : LanguagesArray,
      visible : false,
      shortCode : ShortCode.en_GB
    }
  },
  mounted(){
    this.language = store.state.localization.language;  
  },
  watch : {
    language(newValue, oldValue){
        store.dispatch('SET_LANGUAGE', this.language);
        this.emitter.emit("set-language");
        this.shortCode = this.languages.find((element) => element.code === this.language)!.shortCode 
    } 
  },
  methods:{
      toggleDropdown(event){
          this.visible = !this.visible
          this.$refs.op.toggle(event)
        } 
  },
});
