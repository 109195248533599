<template>
    <ImageForm
        :isProgress="showProgress">
        <template v-slot:title>
            {{isError ? $t('Register.Error') : $t('Register.Title')}}
        </template>
        <template v-slot:context>
            <div v-if="!showMessage" class="justify-content-center">
                    <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                        <div class="mt-5 mb-3">
                            <div class="p-float-label">
                                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted}" />
                                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}">{{$t('Name')}}*</label>
                            </div>
                            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending" class="p-error">{{v$.name.required.$message.replace('Value', $t('Name'))}}</small>
                        </div>
                        <div class="mt-5 mb-3">
                            <div class="p-float-label">
                                <CountrySelector id="country" v-model="v$.code.$model" :country="countryCode" flag="false" :class="{'p-invalid':v$.code.$invalid && submitted}" />
                            </div>
                            <small v-if="(v$.code.$invalid && submitted) || v$.code.$pending" class="p-error">{{v$.code.required.$message.replace('Value', $t('Country'))}}</small>
                        </div>
                        <div class="mt-5 mb-3">
                            <div class="p-float-label p-input-icon-right">
                                <i class="pi pi-envelope" />
                                <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                                <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
                            </div>
                            <span v-if="v$.email.$error && submitted">
                                <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                                <small class="p-error">{{error.$message}}</small>
                                </span>
                            </span>
                            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                        </div>
                        <div class="mt-5 mb-3">
                            <div class="p-float-label">
                                <Password id="password" v-model="v$.password.$model.password" 
                                    :class="{'p-invalid':v$.password.password.$invalid && submitted}"
                                    :weak-label="weakLabel"
                                    :medium-label="mediumLabel"
                                    :strong-label="strongLabel" 
                                    toggleMask>
                                    <template #header>
                                        <h3>{{$t('PickAPassword')}}</h3>
                                    </template>
                                    <template #footer>
                                        <Divider />
                                        <p class="mt-2">{{$t('Suggestions')}}</p>
                                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                            <li v-for="item in suggestionList" :key="item">
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </template>
                                </Password>
                                <label for="password" :class="{'p-error':v$.password.password.$invalid && submitted}">{{$t('Password')}}*</label>
                            </div>
                            
                            <span v-if="(v$.password.password.$invalid && submitted) || v$.password.password.$pending">
                                <span v-for="(error, index)  in v$.password.password.$silentErrors" :key="index"  >
                                    <small class="p-error">{{error.$message.replace('Value', $t('Password'))}}</small>
                                </span>
                            </span>
                        </div>
                        <div class="mt-5 mb-3">
                            <div class="p-float-label">
                                <Password id="confirm" v-model="v$.password.$model.confirm" 
                                    :class="{'p-invalid':v$.password.confirm.$invalid && submitted}"
                                    :feedback="false"
                                    toggleMask
                                    >
                                </Password>
                                <label for="confirm" :class="{'p-error':v$.password.confirm.$invalid && submitted}">
                                    <div v-if="isConfirm" class="confirm inline-flex bg-no-repeat bg-contain"></div>
                                    {{$t('ConfirmPassword')}}*
                                </label>
                            </div>

                            <span v-if="(v$.password.confirm.$invalid && submitted) || v$.password.confirm.$pending">
                                <span v-for="(error, index)  in v$.password.confirm.$silentErrors" :key="index"  >
                                    <small class="p-error">{{error.$message.replace('Value', $t('Password'))}}</small>
                                </span>
                            </span>
                        </div>
                        
                        <div class="field-checkbox mt-5">
                            <Checkbox id="accept" name="accept" value="Accept" v-model="v$.accept.$model" :class="{'p-invalid':v$.accept.$invalid && submitted}" />
                            <label for="accept" :class="{'p-error': v$.accept.$invalid && submitted}"><a :href="$t('Register.AgreementLink')">{{$t('Register.Agreement')}}*</a></label>
                        </div>
                        <Button type="submit" :label="submit" class="form blue" />
                    </form>
            </div>
            <div v-else class="justify-content-center">
                <h2 v-if="!showProgress" :class="{error : isError}">{{message}}</h2>
                <div v-if="isError" class= "flex w-full justify-content-center">
                    <Button @click="resetForm()" :label="buttonTitle" class="red form" />
                </div>
            </div>
        </template>
    </ImageForm>
</template>
<script>
    import register from './register';
    export default register;
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/styles.scss";
    .warn{
        color: $gray;
    }
    .err{
        border-color: $red;
    }
    .error{
        color: $red;
    }
    .confirm{
        width: 15px;
        height: 15px;
        background: url('@/assets/images/ok.png');
    }
</style>