
import { defineComponent } from 'vue';
import Login from '@/components/Login/Login.vue'; 
//import Header from '@/components/Header/Header.vue'; 
//import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    Login
    //, Header
    //, Footer
},
});
