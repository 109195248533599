/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AuthenticationServiceTransport {
  /**
   * Аутентификация пользователя
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginInDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static loginByToken(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/LoginByToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Регистрация нового пользователя
   */
  static registerUser(
    params: {
      /** requestBody */
      body?: UserAddingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/RegisterUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтвеждение регистрации пользователя (непавильно сделано, но сделано для поддержки браузеров и перехода по прямой ссылке)
   */
  static registerConfirm(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/RegisterConfirm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Восстановление пароля пользователя на почту
   */
  static recoveryPassword(
    params: {
      /** Почта пользователя в системе */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/RecoveryPassword';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { email: params['email'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRegisterLink(
    params: {
      /**  */
      lang?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/GetRegisterLink';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lang: params['lang'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCountries(options: IRequestOptions = {}): Promise<CountryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authentication/GetCountries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class BillingServiceTransport {
  /**
   * Получить тразакцию по ID
   */
  static getTransaction(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetTransaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Создать транзакцию для оплаты
   */
  static createTransaction(
    params: {
      /**  */
      minutes?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionAddingResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/CreateTransaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { minutes: params['minutes'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Создать транзакцию для оплаты из тарифного плана
   */
  static createTransactionFromPlan(
    params: {
      /**  */
      planId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionAddingResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/CreateTransactionFromPlan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { planId: params['planId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить баланс пользователя в долларах
   */
  static getBalans(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetBalans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPayLink(
    params: {
      /**  */
      minutes?: number;
      /**  */
      lang?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetPayLink';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { minutes: params['minutes'], lang: params['lang'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCostPerMinute(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetCostPerMinute';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPayResult(
    params: {
      /**  */
      orderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StenogramPayResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetPayResult';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderId: params['orderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Возврат результата платежной системы
   */
  static payResult(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/PayResult';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить доступные тарифные планы
   */
  static getAvailableTariffPlans(options: IRequestOptions = {}): Promise<TariffPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetAvailableTariffPlans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить доступные тарифные планы по типу тарифного плана
   */
  static getAvailableTariffPlansByType(
    params: {
      /** Тип запрашиваемых тарифных планов */
      planType?: TariffPlanType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TariffPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/GetAvailableTariffPlansByType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { planType: params['planType'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Тестовая транзакция
   */
  static testTransaction(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Billing/TestTransaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsServiceTransport {
  /**
   * Получить документ по ID
   */
  static getDocument(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/GetDocument';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Создать документ. Создает дуокумент и открывает транзакцию по загрузке документа.
   */
  static createDocument(
    params: {
      /** requestBody */
      body?: DocumentAddingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/CreateDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Удалить документ
   */
  static deleteDocument(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/DeleteDocument';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Загрузить чанк файла
   */
  static uploadPackage(
    params: {
      /** requestBody */
      body?: UploadDocumentPackageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/UploadPackage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтвердить загрузку
   */
  static commitUpload(
    params: {
      /** requestBody */
      body?: DocumentId;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/CommitUpload';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Прервать загрузку файла документа
   */
  static rejectUpload(
    params: {
      /** requestBody */
      body?: DocumentId;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/RejectUpload';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить список документов проекта
   */
  static getDocumentsOfProject(
    params: {
      /** Идентификатор проекта */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/GetDocumentsOfProject';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ProjectId: params['projectId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Скачать файл, связанный с документом
   */
  static downloadFile(
    params: {
      /** Идентификатор документа */
      documentId?: number;
      /** Имя файла, под которым будет сохранен документ или выкачен в браузер, если это надо */
      fileName?: string;
      /** Заголовок нужен, чтобы принудительно скачивать файл, а не открывать его напрямую в браузере */
      addForceDownloadHeader?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/DownloadFile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        documentId: params['documentId'],
        fileName: params['fileName'],
        addForceDownloadHeader: params['addForceDownloadHeader']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Начать распознавание
   */
  static start(
    params: {
      /** requestBody */
      body?: JobAddingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/Start';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверить задание и получить результат
   */
  static getResult(
    params: {
      /**  */
      documentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/GetResult';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { documentId: params['documentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить статус документа
   */
  static getStatus(
    params: {
      /** идентификатор задания */
      documentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentStatus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { documentId: params['documentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBackendApi(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Documents/GetBackendApi';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HomeServiceTransport {
  /**
   *
   */
  static getApiVersion(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/GetAPIVersion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProjectsServiceTransport {
  /**
   * Получить список доступных проектов, доступных пользователю
   */
  static getAvailableProjects(options: IRequestOptions = {}): Promise<ProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/GetAvailableProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAvailableProjectDocuments(options: IRequestOptions = {}): Promise<ProjectDocumentsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/GetAvailableProjectDocuments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить проект по идентификатору
   */
  static getProject(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/GetProject';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Создает новый проект
   */
  static addProject(
    params: {
      /** requestBody */
      body?: ProjectAddingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/AddProject';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Изменить проект или пресет проекта
   */
  static alterProject(
    params: {
      /** requestBody */
      body?: ProjectDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/AlterProject';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Удалить проект
   */
  static deleteProject(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Projects/DeleteProject';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UserServiceTransport {
  /**
   * Получить данные пользователя
   */
  static getCurrentUser(options: IRequestOptions = {}): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/GetCurrentUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Изменение пароля пользователя
   */
  static changePassword(
    params: {
      /** Новый пароль пользователя в системе */
      newPassword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { newPassword: params['newPassword'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Отправить вопрос пользователя
   */
  static askMe(
    params: {
      /** Сообщение пользователя */
      message?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/AskMe';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { message: params['message'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Изменение Email пользователя
   */
  static changeEmail(
    params: {
      /** Новый Email пользователя в системе */
      newEmail?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/ChangeEmail';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { newEmail: params['newEmail'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(options: IRequestOptions = {}): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/GetUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/GetUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтвеждение Email пользователя по ссылке, которая отправлена на почту
   */
  static emailConfirm(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/EmailConfirm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static goToAccount(
    params: {
      /**  */
      lang?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/GoToAccount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lang: params['lang'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Установка баланса текущего пользователя
   */
  static setAmount(
    params: {
      /** Баланс пользотвателя */
      amount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/SetAmount';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { Amount: params['amount'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Установка баланса текущего пользователя. В случае отрицательного числа происходит списание суммы с баланса, в случае положительного - пополнение.
Если отрицательное число больше текущего баланса пользователя - генерируется исключение - Не хвататет средств для списания, списания не происходит.
 */
  static changeAmount(
    params: {
      /** Сумма для изменения баланса пользователя */
      amount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/ChangeAmount';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { Amount: params['amount'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Изменение страны пользователя
   */
  static changeCountry(
    params: {
      /** Новая страна пользователя */
      newCountry?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/User/ChangeCountry';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { newCountry: params['newCountry'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface CountryDto {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  code2?: string;

  /**  */
  name?: string;

  /**  */
  currency?: PayCurrency;
}

export interface Document {
  /**  */
  id?: number;

  /**  */
  projectId?: number;

  /**  */
  name?: string;

  /**  */
  uid?: string;

  /**  */
  textForIndexing?: string;

  /**  */
  size?: number;

  /**  */
  compressedSize?: number;

  /**  */
  hash?: string;

  /**  */
  duration?: number;

  /**  */
  processTime?: number;

  /**  */
  amazonUploadTime?: number;

  /**  */
  fileDuration?: number;

  /**  */
  status?: DocumentStatus;

  /**  */
  compressionType?: CompressionType;

  /**  */
  createDate?: Date;

  /**  */
  modifyDate?: Date;

  /**  */
  project?: Project;
}

export interface DocumentAddingDto {
  /**  */
  projectId?: number;

  /**  */
  name: string;

  /**  */
  size?: number;

  /**  */
  duration?: number;
}

export interface DocumentDto {
  /**  */
  projectId?: number;

  /**  */
  name: string;

  /**  */
  size?: number;

  /**  */
  duration?: number;

  /**  */
  id?: number;

  /**  */
  hash?: string;

  /**  */
  createDate?: Date;

  /**  */
  modyfyDate?: Date;

  /**  */
  status?: DocumentStatus;

  /**  */
  uid?: string;
}

export interface DocumentId {
  /**  */
  id?: number;
}

export interface JobAddingDto {
  /**  */
  documentId?: number;

  /**  */
  speakers?: number;

  /**  */
  language?: string;
}

export interface LoginInDto {
  /**  */
  email: string;

  /**  */
  password: string;

  /**  */
  deviceType?: number;
}

export interface PayTransaction {
  /**  */
  id?: number;

  /**  */
  guid?: string;

  /**  */
  userId?: number;

  /**  */
  createDate?: Date;

  /**  */
  currency?: string;

  /**  */
  currencyRatio?: number;

  /**  */
  ammount?: number;

  /**  */
  minutes?: number;

  /**  */
  result?: PayResult;

  /**  */
  errorCode?: string;

  /**  */
  error?: string;

  /**  */
  description?: string;

  /**  */
  language?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  fingerprint?: string;

  /**  */
  user?: User;
}

export interface Project {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  hash?: string;

  /**  */
  createDate?: Date;

  /**  */
  modifyDate?: Date;

  /**  */
  userId?: number;

  /**  */
  user?: User;

  /**  */
  documents?: Document[];

  /**  */
  stenogramType?: StenogramType;
}

export interface ProjectAddingDto {
  /**  */
  name: string;

  /**  */
  description?: string;
}

export interface ProjectDocumentsDto {
  /**  */
  projectId?: number;

  /**  */
  projectName?: string;

  /**  */
  projectDescription?: string;

  /**  */
  projectHash?: string;

  /**  */
  stenogramType?: StenogramType;

  /**  */
  projectCreateDate?: Date;

  /**  */
  projectModifyDate?: Date;

  /**  */
  documentId?: number;

  /**  */
  documentName?: string;

  /**  */
  documentHash?: string;

  /**  */
  documentCreateDate?: Date;

  /**  */
  documentModyfyDate?: Date;

  /**  */
  documentStatus?: DocumentStatus;

  /**  */
  documentUid?: string;

  /**  */
  documentDuration?: number;

  /**  */
  documentSize?: number;
}

export interface ProjectDto {
  /**  */
  name: string;

  /**  */
  description?: string;

  /**  */
  id?: number;

  /**  */
  hash?: string;

  /**  */
  stenogramType?: StenogramType;

  /**  */
  createDate?: Date;

  /**  */
  modifyDate?: Date;

  /**  */
  documents?: Document[];

  /**  */
  projectSize?: number;

  /**  */
  projectDuration?: number;
}

export interface Question {
  /**  */
  id?: number;

  /**  */
  userId?: number;

  /**  */
  createDate?: Date;

  /**  */
  message?: string;

  /**  */
  language?: string;

  /**  */
  user?: User;
}

export interface Role {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  users?: User[];
}

export interface StenogramPayResultDto {
  /**  */
  orderID?: number;

  /**  */
  amount?: number;

  /**  */
  balans?: number;

  /**  */
  error?: string;

  /**  */
  errorCode?: string;

  /**  */
  currency?: string;
}

export interface TariffPlanDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  minutes?: number;

  /**  */
  amount?: number;

  /**  */
  costPerMinute?: number;

  /**  */
  planType?: TariffPlanType;

  /**  */
  currency?: PayCurrency;
}

export interface TransactionAddingResultDto {
  /**  */
  payLink?: string;

  /**  */
  transactionId?: number;
}

export interface TransactionDto {
  /**  */
  id?: number;

  /**  */
  guid?: string;

  /**  */
  userId?: number;

  /**  */
  createDate?: Date;

  /**  */
  currency?: string;

  /**  */
  ammount?: number;

  /**  */
  minutes?: number;

  /**  */
  result?: PayResult;

  /**  */
  errorCode?: string;

  /**  */
  error?: string;

  /**  */
  isDeleted?: boolean;
}

export interface UploadDocumentPackageDto {
  /**  */
  documentId?: number;

  /**  */
  packageBuffer?: string;
}

export interface User {
  /**  */
  id?: number;

  /**  */
  passwordHash?: string;

  /**  */
  fullName?: string;

  /**  */
  email?: string;

  /**  */
  isActive?: boolean;

  /**  */
  isBlock?: boolean;

  /**  */
  amount?: number;

  /**  */
  createDate?: Date;

  /**  */
  lastLogin?: Date;

  /**  */
  token?: string;

  /**  */
  phone?: string;

  /**  */
  country?: string;

  /**  */
  roleId?: number;

  /**  */
  role?: Role;

  /**  */
  transactions?: PayTransaction[];

  /**  */
  questions?: Question[];

  /**  */
  deviceId?: DeviceId;
}

export interface UserAddingDto {
  /**  */
  password?: string;

  /**  */
  fullName?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  country?: string;
}

export interface UserDto {
  /**  */
  password?: string;

  /**  */
  fullName?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  country?: string;

  /**  */
  id?: number;

  /**  */
  isActive?: boolean;

  /**  */
  amount?: number;

  /**  */
  createDate?: Date;

  /**  */
  lastLogin?: Date;

  /**  */
  token?: string;

  /**  */
  deviceId?: DeviceId;
}

export type CompressionType = 0 | 1 | 2 | 3;

export type DeviceId = 0 | 1;

export type DocumentStatus = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type PayCurrency = 1 | 2;

export type PayResult = 0 | 1 | 2 | 3 | -1;

export type StenogramType = 0 | 1;

export type TariffPlanType = 1 | 2;
