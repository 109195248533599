
import { defineComponent } from 'vue'
import Dropdown from 'primevue/dropdown'
import { AuthenticationService } from '@/api/rest/restClient';

export default defineComponent({
  name: 'CountryFlag',
  props:['modelValue','country'],
  emits: ['update:modelValue'],
  components:{
    Dropdown,
  },
  data() {
    return {
        countries : [],
        code : ''
    }
  },
  methods:{
    getCountries(){
        AuthenticationService.getCountries()
        .then((result) => {
                this.countries = result
                this.findCountry()
            })
            .catch((err) => {
                console.error(err?.response?.data as string);
            });
    },
    getFlag(code){
        return 'fi-' + code.toLowerCase()
    },
    findCountry(){
        this.selectedCountry = this.countries.find((c) => c.code === this.code)
    }
  },
  computed: {
    selectedCountry: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  created(){
     this.getCountries()
  },
  mounted() { 
    this.emitter.on("set-language", () => {
      this.getCountries()
    })
  },
  watch : {
    country: {
        immediate: true,
        handler(val, oldVal) {
          this.code = val
          this.findCountry()
        },   
    }
  }
});
