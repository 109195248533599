import { defineComponent, } from 'vue';
import { AuthenticationService } from '@/api/rest/restClient';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import {$t, i18n} from '@/utils/i18n'

export default defineComponent({
    components:{
        Password,
        Button,
        InputText,
        Checkbox,
    },
    name: 'RegisterConfirm',
    props:{
        token : String
      },
    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { required, email },
        }
    },
    data(){
      return {
        message:'',
        isError : false,
        progress : true,
        date: null,
        title: $t('RegisterConfirm')
      }
    },
    created() {
        this.registerConfirm()
    },
    mounted() {
    },
    computed:{
    },
    methods: {
      registerConfirm: function () {
        let token = this.token as string
        console.log(email);
        this.showProgress = true
        this.isError = false
        AuthenticationService.registerConfirm({token})
            .then(data => this.processRegisterConfirm())
            .catch(err => this.processRegisterError(err?.response?.data))
      },
      processRegisterConfirm(): void {
        this.message = $t('Register.ConfirmSuccess')
        this.progress = false;
      },
      processRegisterError(message : string): void {
        this.message = message
        this.progress = false
        this.isError = true
      }
    }
  })
