import { defineComponent, } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ProjectDto } from '@/api/rest/serviceTransport';
import { ProjectService } from '@/api/rest/restClient';
import { $t } from '@/utils/i18n';
import Formatter from '@/helpers/formatters';

export default defineComponent({
    components:{
        Password,
        Button,
        InputText,
        Checkbox,
        DataTable,
        Column
    },
    name: 'Projects',
    props:{
        token : String
      },
    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { required, email },
        }
    },
    data(){
      return {
        isError : false,
        progress : true,
        projects : [] as ProjectDto[],
        expandedRows : []       
      }
    },
    mounted() {
        this.getProjects()
    },
    computed:{
    },
    methods: {
      formatDate(value: Date ) {
        return new Date(value).toLocaleDateString($t('LocaleString'), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
      },
      formatTime(value: Date ) {
        return new Date(value).toLocaleTimeString($t('LocaleString'));
      },
      formatFullDate(value: Date ) {
        return (value == null || value == undefined) ? 'Нет данных' : 
          this.formatDate(value) + '\n' + this.formatTime(value)
      },
      formatSize(size : number) {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) as unknown as number * 1 + ' ' + [$t('Size.byte'), $t('Size.kilobyte'), $t('Size.megabyte'), $t('Size.gigabyte'), $t('Size.terabyte')][i];
      },
      formatSeconds(seconds : number) {
        return Formatter.formatSeconds(seconds)
      },
      getProjects(){
          this.progress = true  
          ProjectService.getAvailableProjects( {})
                .then(data => {
                  this.projects = data as ProjectDto[]
                  this.progress = false
                })
                .catch(err => this.toggleError(err?.response?.data))
        },
      toggleError(err : string){
              this.$toast.add({severity:'error', summary: $t('Error'), detail:err, life: 3000});  
      },
      rowClass(data:any){
        return data.stenogramType === 'stenogramPro' ? 'pro' : ''
      },
      documentRow(data:any){
        return 'row'
      },
    }
  })
