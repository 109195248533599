
import { defineComponent } from 'vue'
import { version } from '@/constants/appVersion'
import { apiUrlPath } from '@/constants/apiUrl'
import store from '@/store/'
import Button from 'primevue/button'
import LangSelector from '@/components/LangSelector/LangSelector.vue'
import HeaderNavigator  from '@/components/HeaderNavigator/HeaderNavigator.vue'
import { $t } from '@/utils/i18n'

export default defineComponent({
  name: 'Header',
  components:{
    LangSelector,
    Button,
    HeaderNavigator
  },
  data() {
    return {
      version: version,
      url: apiUrlPath.url!.replace('https://', ''),
    };
  },
  created(){
    
  },
  computed:{
    routes(){ 
      return [
        {
          name : 'Stenogram',
          link: 'https://stenogram.pro/stenogram-basic/'
        },
        {
          name : 'Stenogram Pro',
          link: 'https://stenogram.pro/stenogram-pro/'
        },
        {
          name : 'On-Premise',
          link: 'https://stenogram.pro/stenogram-on-premise/'
        },
        {
          name : $t('About'),
          link: 'https://stenogram.pro/o-kompanii/'
        },
      ]
    }
  },
  watch:{
    isAuth : bool => store.getters.isAuthenticated
  }
});
