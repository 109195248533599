import { englishLocalization } from './localization.english';
import { russainLocalization } from './localization.russain';
import { LanguageCode } from '@/enums/localization/localization.enum';

export interface IGlobalLocalLocalization {
    messages: object;
    languageCode: LanguageCode;
  }

export const globalLocalLocalization = [englishLocalization, russainLocalization];

