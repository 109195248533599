import { defineComponent } from 'vue';
import Button from 'primevue/button';
import { UserDto } from '@/api/rest/serviceTransport';
import store from '@/store';
import { MutationTypes } from '@/store/mutations';
import UserMenu from '@/components/UserMenu/UserMenu.vue'
import Account from '@/components/Account/Account.vue'
import Projects from '@/components/Projects/Projects.vue'
import Billing from '@/components/Billing/Billing.vue'
import AddQuestion from '@/components/AddQuestion/AddQuestion.vue'
export default defineComponent({
    name : 'User',
    emits:['account'],
    components:{
        Button,
        UserMenu,
        Account,
        Projects,
        Billing,
        AddQuestion
    },
    data() {
        return {
            currentUser : store.getters.currentUser as UserDto,
            isProject : true
        }
    },
    methods:{
        logout(){
            store.commit(MutationTypes.LOGOUT);
        },
        
    }
})