import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ecc430e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card content pt-8" }
const _hoisted_2 = { class: "flex main-form" }
const _hoisted_3 = { class: "flex flex-wrap align-content-center layout-logo w-8" }
const _hoisted_4 = { class: "flex flex-wrap content w-4 justify-content-center align-content-center p-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "title w-full" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_lottie_animation, {
          class: "w-full",
          ref: "anim",
          loop: false,
          autoPlay: true,
          animationData: require('@/assets/animations/logo.json')
        }, null, 8, ["animationData"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.isProgress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h1", null, [
                  _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                ])
              ]),
              _renderSlot(_ctx.$slots, "context", {}, undefined, true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_lottie_animation, {
                ref: "anim",
                loop: true,
                autoPlay: true,
                animationData: require('@/assets/animations/progress.json')
              }, null, 8, ["animationData"])
            ]))
      ])
    ])
  ]))
}