import { LanguageCode } from '@/enums/localization/localization.enum'
import { globalLocalLocalization } from '@/constants/localization/localization.global'
import { englishLocalization } from '@/constants/localization/localization.english'
import { i18n } from '@/utils/i18n';
import  Api  from '@/api/api';

export interface IStateLocalizationTypes {
  language: string;
}

const state: IStateLocalizationTypes = {
  language: (localStorage.getItem('language')! as string) || 'en-GB',
};

const getters = {
    GET_LOCALIZATION: (state) => state.language
  }

const mutations = {
    SET_LANGUAGE: (state, language: LanguageCode) => {
      state.language = language;
      setLocalization(language);
    }
  }

const actions = {
     
  SET_LANGUAGE: (context, language : LanguageCode) => {
      context.commit('SET_LANGUAGE', language);
    }
}

const setLocalization = (lang: LanguageCode) => {
  localStorage.setItem('language', lang);
  Api.instance.setLanguage(lang);
  const localization = addLocalMessages(lang, englishLocalization.messages);
  i18n.global.setLocaleMessage(lang, localization);
  i18n.global.locale = lang;
  return localization;
  };

const addLocalMessages = (language: LanguageCode, defaultValue : object) => {
    const localization = globalLocalLocalization.find((globalLocalization) => globalLocalization.languageCode === language);
    return localization?.messages ? (defaultValue = { ...defaultValue, ...localization.messages }) : (defaultValue = { ...defaultValue, ...englishLocalization.messages });
  }; 

export default {
  state,
  getters,
  mutations,
  actions,
};