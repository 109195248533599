import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-351eab89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-center flex-wrap card-container main-form" }
const _hoisted_2 = { class: "flex justify-content-center align-items-center context grid" }
const _hoisted_3 = { class: "align-items-center col-12" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center col-12 my-5 message" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "align-items-center col-12 my-5 message" }
const _hoisted_7 = { style: {"text-align":"center"} }
const _hoisted_8 = {
  key: 0,
  class: "flex justify-content-center align-items-center my-5 btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.progress)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex justify-content-center align-items-center header",  {error: _ctx.isError}])
        }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.isError ? _ctx.$t('Register.ConfirmError') : _ctx.$t('Register.Confirm')), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.progress)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.isError ? require('@/assets/images/error.png') : require('@/assets/images/ok.png')
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createBlock(_component_lottie_animation, {
                key: 1,
                ref: "anim",
                loop: true,
                autoPlay: true,
                animationData: require('@/assets/animations/progress.json')
              }, null, 8, ["animationData"]))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.message), 1)
        ]),
        (!_ctx.progress && !_ctx.isError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_router_link, { to: "/login" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, { class: "red" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Login.Title')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}