import { defaultLanguage } from './constants/localization/localization.constans'
//import { createI18n } from 'vue-i18n'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { apiUrlPath } from './constants/apiUrl';
import Api from './api/api';
import {i18n} from '@/utils/i18n'
/* primevue */
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import '@/assets/styles/paginator.scss';
import LottieAnimation from 'lottie-web-vue';
import Tooltip from 'primevue/tooltip';
import mitt from 'mitt'
import { AuthenticationService } from './api/rest/restClient'
import { ICurrentUser } from './interfaces/local-interfaces/authentication/user-dto'
import { useRoute } from 'vue-router'



const app = createApp(App)
const emitter = mitt()


// PrimeVue components
app.use(PrimeVue, {
  ripple: true,
  zIndex: {
    modal: 120000, //dialog, sidebar
    overlay: 10000, //dropdown, overlaypanel
    menu: 10000, //overlay menus
    tooltip: 11000, //tooltip
  },
});

app.use(LottieAnimation)
app.use(i18n)
app.component('Toast', Toast)
app.directive('tooltip', Tooltip)
app.use(ToastService)
app.config.globalProperties.emitter = emitter
app.use(store)


async function getRuntimeConfig(){
  //TODO init config
  const runtimeConfig = await fetch('/config.json');
  let config = await runtimeConfig.json();
  let url = config['BackendUrl'];
  apiUrlPath.url = url;
  apiUrlPath.urlBase = url;
};

async function initApp(){
  let lang = (localStorage.getItem('language')! as string) || defaultLanguage as string
  await Api.instance.reRegister()
  store
    .dispatch('SET_LANGUAGE', lang)
    .finally(async () => {
      await appMount()
    })
  if(store.getters.isAuthenticated){
    Api.instance.authorize(store.getters.token)
  }
};

async function appMount(){
  app.use(router)
  await router.isReady();
  app.mount('#app')
}

getRuntimeConfig().finally(() => {
  initApp();
});

export { app };
