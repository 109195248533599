import { defineComponent } from 'vue';
import Button from 'primevue/button';
import { UserDto } from '@/api/rest/serviceTransport';
import store from '@/store';
import { MutationTypes } from '@/store/mutations';
import UserMenu from '@/components/UserMenu/UserMenu.vue'
import CountryFlag from '@/components/CountryFlag/CountryFlag.vue'
import { $t } from '@/utils/i18n';
import { UserService } from '@/api/rest/restClient';
import Projects from '@/components/Projects/Projects.vue'
import Formatter from '@/helpers/formatters';
export default defineComponent({
    name : 'Account',
    emits: ["pay"],
    components:{
        Button,
        UserMenu,
        CountryFlag,
        Projects
    },
    data() {
        return {
            country : null,
            currentUser : store.getters.currentUser as UserDto
        }
    },
    methods:{
        logout(){
            store.commit(MutationTypes.LOGOUT);
                //router.push('/');    
        },
        download(){
            console.info('Download')
        },
        formatAmount(seconds) {
            return Formatter.formatAmountSeconds(seconds)
        },
        changeCountry(){
            this.currentUser.country = this.country.code
            UserService.changeCountry({newCountry : this.currentUser.country})
                .then(data => {
                    this.currentUser.token = data
                    let session = store.getters.isSession
                    store.commit(MutationTypes.SET_CURRENT_USER, this.currentUser as UserDto)
                    store.commit(MutationTypes.SET_TOKEN, { token: this.currentUser.token, isSession: session });
                })
                .catch(err => console.log(err?.response?.data)) 
        }
    }
})